// @ts-strict-ignore
import * as styles from './SummaryCartPrivilegeItem.styles'
import { Image, Link, Text } from '@gassan-ui'
import { CartFragmentFragment } from '@generated'
import { cn } from '@lib/cn'
import { formatPrice } from '@lib/formatPrice'
import { imgix } from '@lib/imigx'
import { motion } from 'framer-motion'
import { useTranslation } from 'next-i18next'
import { FC } from 'react'

type SummaryCartPrivilegeItemProps = CartFragmentFragment['privilegeItems'][0] & {
  onRemove?: (id: string) => void
  variant?: 'pampas' | 'white'
}

export const SummaryCartPrivilegeItem: FC<SummaryCartPrivilegeItemProps> = ({
  onRemove,
  variant = 'white',
  imageSource,
  description,
  id,
  value,
}) => {
  const { t } = useTranslation(['checkout', 'other'])
  return (
    <motion.div
      className={styles.container}
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{ duration: 0.2, ease: 'easeOut' }}
    >
      <div className={styles.imgContainer}>
        <Image
          sources={[imgix(imageSource, { width: 180 })]}
          alt={description}
          className={cn('aspect-square', variant === 'pampas' ? 'bg-white' : 'bg-shade-100')}
        />
      </div>
      <div className={styles.flexContainer}>
        <Text variant="regular-bold" className="mb-1 text-left">
          {description}
        </Text>
        {onRemove && (
          <Link as="button" onClick={() => onRemove(id)} variant="brown">
            {t('remove', { ns: 'other' })}
          </Link>
        )}
        <div className={styles.showOnMobile}>
          <Text className="mb-1" style={{ textDecoration: 'line-through' }}>
            {formatPrice(value, true)}
          </Text>
          <Text variant="regular-bold" className="mb-1">
            {t('free', { ns: 'other' })}
          </Text>
          <Text className="mb-1">{t('privilege', { ns: 'checkout' })}</Text>
        </div>
      </div>
      <div className={styles.hideOnMobile}>
        <Text className="mb-1" style={{ textDecoration: 'line-through' }}>
          {formatPrice(value, true)}
        </Text>
        <Text variant="regular-bold" className="mb-1">
          {t('free', { ns: 'other' })}
        </Text>
        <Text className="mb-1">{t('privilege', { ns: 'checkout' })}</Text>
      </div>
    </motion.div>
  )
}
