// @ts-strict-ignore
import { Item } from './types'
import { ArticlesQuery } from '@generated'

export function articlesToDataLayerItems(products: ArticlesQuery['articles']['items']): Item[] {
  if (!products || products.length === 0) {
    return []
  }
  let items: Item[] = []

  products.forEach((item, index) => {
    items.push({
      item_id: item.id,
      item_name: item.description,
      currency: 'EUR',
      index,
      item_brand: item.brand,
      item_category: 'Apparel & Accessories',
      item_category2: 'Jewelry',
      item_category3: item.articleType === 'Horloge' ? 'Watches' : undefined,
      item_list_id: 'related_products',
      item_list_name: 'Related Products',
      price: Math.round((item.salePrice / 121 || item.price / 121) * 100) / 100,
      quantity: 1,
    })
  })

  return items
}
