// @ts-strict-ignore
import QuickCartFooter from './Footer'
import SummaryCartItem from '@components/SummaryCartItem'
import { SummaryCartPrivilegeItem } from '@components/SummaryCartPrivilegeItem'
import { Box, CleanButton, Flex, Grid, OldHeading, Icon } from '@gassan-ui'
import { useCart } from '@lib/hooks/useCart'
import { imgix } from '@lib/imigx'
import Portal from '@reach/portal'
import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock'
import { AnimatePresence, motion } from 'framer-motion'
import { useTranslation } from 'next-i18next'
import React, { FC, useEffect, useRef } from 'react'
import styled from 'styled-components'

const Backdrop = styled(motion.div)`
  background: rgba(0, 0, 0, 0.4);
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  cursor: pointer;
  z-index: ${(p) => p.theme.zIndices.quickCart};
`

const Sidebar = styled(motion.div)`
  position: fixed;
  right: 0;
  top: 0;
  height: 100%;
  width: 30rem;
  background: white;
  padding-top: 2rem;
  padding-bottom: 2rem;
  cursor: initial;
`

type Props = {
  onRequestClose?: () => void
  handleRemoveItemFromCart: (id: string, unitPrice: number, quantity: number) => void
  handleRemovePrivilegeItemFromCart?: (id: string) => void
}

const QuickCart: FC<Props> = ({
  onRequestClose,
  handleRemoveItemFromCart,
  handleRemovePrivilegeItemFromCart,
}) => {
  const cart = useCart()
  const ref = useRef<HTMLDivElement>()
  const scrollRef = useRef<HTMLDivElement>()
  const { t } = useTranslation('other')
  const items = cart?.items || []
  const privilegeItems = cart?.privilegeItems || []

  const handleBackdropClick = (e: React.MouseEvent<HTMLElement, MouseEvent>) => {
    if (ref.current === e.target) {
      onRequestClose()
    }
  }

  useEffect(() => {
    // The ref value 'scrollRef.current' will likely have changed by the time this effect cleanup function runs.
    // If this ref points to a node rendered by React, copy 'scrollRef.current' to a variable inside the effect
    const scrollNode = scrollRef && scrollRef.current
    if (scrollNode) {
      disableBodyScroll(scrollNode)
    }

    return () => {
      scrollNode && enableBodyScroll(scrollNode)
    }
  })

  return (
    <Portal>
      <AnimatePresence>
        <Backdrop
          ref={ref}
          data-test-id="quick-cart-backdrop"
          onClick={handleBackdropClick}
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
        >
          <Sidebar
            data-test-id="quick-cart"
            initial={{ opacity: 0, x: 150 }}
            animate={{
              opacity: 1,
              x: 0,
              transition: { delay: 0.2, duration: 0.2, ease: 'easeOut' },
            }}
          >
            <Grid
              position="relative"
              height="100%"
              gridTemplateRows={'auto 1fr auto'}
              gridAutoFlow="row"
            >
              <Flex
                justifyContent="space-between"
                alignItems="center"
                mb={{ _: '2rem', large: '1.5rem' }}
                px="2rem"
              >
                <OldHeading mb="0" variant="h3">
                  {t('shoppingCart')}
                </OldHeading>
                <CleanButton onClick={() => onRequestClose()}>
                  <Icon icon="cross" />
                </CleanButton>
              </Flex>
              <Box overflow="auto" pt="0.5rem" px="2rem">
                {(items || privilegeItems) && (
                  <AnimatePresence>
                    {privilegeItems.map((item) => {
                      return (
                        <SummaryCartPrivilegeItem
                          key={item.id}
                          onRemove={() => handleRemovePrivilegeItemFromCart(item.id)}
                          {...item}
                        />
                      )
                    })}
                    {items.map((item, key) => (
                      <SummaryCartItem
                        key={`${item.article.id}-${item.size}-${key}`}
                        message={item.message}
                        title={item.article.brand}
                        description={item.article.description}
                        count={item.quantity > 1 && item.quantity}
                        price={item.unitPrice * item.quantity}
                        salePrice={item.article.salePrice && item.article.salePrice * item.quantity}
                        discount={
                          item.article.salePrice &&
                          Math.round((1 - item.article.salePrice / item.article.price) * 100)
                        }
                        src={imgix(item.article.images[0], { width: 180 })}
                        onRemoveItem={() =>
                          handleRemoveItemFromCart(item.id, item.unitPrice, item.quantity)
                        }
                      />
                    ))}
                  </AnimatePresence>
                )}
              </Box>
              {cart && <QuickCartFooter cart={cart} />}
            </Grid>
          </Sidebar>
        </Backdrop>
      </AnimatePresence>
    </Portal>
  )
}

export default QuickCart
