import LocalizedLink from '@components/Localization/LocalizedLink'
import { cn } from '@lib/cn'
import React from 'react'
import { Text } from '@gassan-ui'
import { SchemaOrg } from './schema-org'

export type BreadcrumbItem = {
  label: string
  href?: string
}

type Props = {
  className?: string
  items: BreadcrumbItem[]
}

export const Breadcrumbs: React.FC<Props> = ({ items, className }) => {
  const breadcrumbItems = [{ href: '/', label: 'Home' }, ...items]

  return (
    <div className={cn('mb-4 hidden w-[90%] text-shade-500 lg:flex', className)}>
      <SchemaOrg variant="breadcrumb" data={items} />
      {breadcrumbItems.map((item, index) => (
        <div className="flex items-center lg:max-w-[35%] xl:max-w-[40%]" key={index}>
          {index > 0 && (
            <span className="mx-2 my-auto h-[10px] w-[1px] rotate-[20deg] bg-current lg:mx-4" />
          )}
          {!item.href || index === breadcrumbItems.length - 1 ? (
            <Text
              variant="micro"
              className="mb-0 overflow-hidden text-ellipsis whitespace-nowrap text-current"
              as="span"
            >
              {item.label}
            </Text>
          ) : (
            <LocalizedLink href={item.href} passHref>
              <a className="overflow-hidden text-ellipsis whitespace-nowrap text-current transition-colors duration-150 hover:text-shade-800">
                <Text variant="micro" className="mb-0 !text-inherit">
                  {item.label}
                </Text>
              </a>
            </LocalizedLink>
          )}
        </div>
      ))}
    </div>
  )
}
