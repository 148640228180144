// @ts-strict-ignore
import LocalizedLink from '@components/Localization/LocalizedLink'
import { Box, Button, Flex, OldText, theme } from '@gassan-ui'
import { CartFragmentFragment, useCheckoutAddressesQuery } from '@generated'
import { dataLayerBeginCheckout } from '@lib/data-layer-social-ads/begin-checkout'
import { cartItemsToDataLayerItems } from '@lib/data-layer-social-ads/helpers/cartItemsToDataLayerItems'
import { formatPrice } from '@lib/formatPrice'
import { useAuth } from '@lib/hooks/use-auth'
import { useTranslation } from 'next-i18next'
import { FC } from 'react'

const QuickCartFooter: FC<{ cart: CartFragmentFragment }> = ({ cart }) => {
  const { isLoggedIn } = useAuth()
  const { t } = useTranslation(['other', 'global', 'navigation'])
  // Prefetch checkout addresses if logged in, so they show up inmediatly in the next step
  useCheckoutAddressesQuery({ pause: !isLoggedIn })
  return (
    <Box width="100%" px="2rem">
      <Flex
        justifyContent="space-between"
        alignItems="flex-start"
        py="1rem"
        borderTop={`1px solid ${theme.colors.shades[200]}`}
        borderBottom={`1px solid ${theme.colors.shades[200]}`}
      >
        <Box>
          <OldText mb="0.5rem">{t('total', { ns: 'other' })}</OldText>
          <OldText variant="micro" color="shades.500" mb="0">
            {t('free-delivery', { ns: 'global' })}
          </OldText>
        </Box>
        <Box textAlign="right">
          <OldText mb="0.5rem" variant="regularBold">
            {formatPrice(cart.totalPriceIncTax, true)}
          </OldText>
          <OldText variant="micro" color="shades.500" mb="0">
            {cart.totalTax ? (
              <>
                {t('including', { ns: 'other' })} {formatPrice(cart.totalTax, true)}
                {`${t('vat', { ns: 'other' })} in NL`}
              </>
            ) : (
              t('noVatCharged', { ns: 'other' })
            )}
          </OldText>
        </Box>
      </Flex>
      <Box pt="1rem">
        <LocalizedLink
          href={
            isLoggedIn
              ? t('checkout.order', { ns: 'navigation' })
              : t('checkout.index', { ns: 'navigation' })
          }
        >
          <Button
            className="mb-4 w-full min-w-full"
            variant="dark"
            as="link"
            onClick={beginCheckout}
          >
            {t('order', { ns: 'other' })}
          </Button>
        </LocalizedLink>
        <LocalizedLink href={t('secondaryNav.cart.url', { ns: 'navigation' })}>
          <Button variant="outline" as="link" style={{ width: '100%' }}>
            {t('toShoppingCart', { ns: 'other' })}
          </Button>
        </LocalizedLink>
      </Box>
    </Box>
  )

  function beginCheckout(): boolean {
    if (cart && cart.items) {
      const items = cartItemsToDataLayerItems(cart.items)
      dataLayerBeginCheckout(items)
    }
    return true
  }
}

export default QuickCartFooter
