import { parse } from '@config/theme';
export const container = parse({
  display: 'grid',
  mb: '8',
  textAlign: 'left'
}, "c1suip6s");
export const imgContainer = parse({
  position: 'relative'
});
export const flexContainer = parse({
  display: 'flex',
  alignItems: 'flex-start'
}, "f11227a6");
export const showOnMobile = parse({
  display: 'flex',
  textAlign: 'left'
}, "stu8igf");
export const hideOnMobile = parse({
  display: 'none',
  textAlign: 'left'
}, "h1lp58mv");

require("./SummaryCartPrivilegeItem.styles.linaria.module.css!=!../node_modules/@linaria/webpack5-loader/lib/outputCssLoader.js?cacheProvider=!./SummaryCartPrivilegeItem.styles.ts");