export function initializeContactForm(
  variant: 'appointment' | 'contact',
  isControlledOpen?: boolean,
) {
  const applicableClickHandler = variant === 'appointment' ? clickAppointment : clickContact

  // if the form's modal is already open from parent, apply the click handler
  if (isControlledOpen) {
    setTimeout(applicableClickHandler, 0)
    return
  }

  // if a button directly controlling the modal's status is found, click it
  const button = document.querySelector('[data-open-contact-modal]') as HTMLButtonElement
  if (button) {
    button.click()
    setTimeout(applicableClickHandler, 0)
    return
  }

  // if button can't be found, try finding the form on the page and scroll to it
  const form = document.querySelector('[data-contact-form]') as HTMLFormElement
  if (form) {
    const formTop = form.getBoundingClientRect().top
    const scrollPosition = formTop + window.scrollY - 100
    window.scrollTo({ top: scrollPosition, behavior: 'smooth' })

    applicableClickHandler()
    return
  }
}

function clickAppointment() {
  const appointmentRadioItem = document.querySelector('#make-appointment') as HTMLInputElement
  if (appointmentRadioItem) {
    appointmentRadioItem.click()
  }
}

function clickContact() {
  const contactRadioItem = document.querySelector('#ask-question') as HTMLInputElement
  if (contactRadioItem) {
    contactRadioItem.click()
  }
}
