import { Text } from '@gassan-ui'
import { Box, Flex, Grid, Image, Link, OldText, breakpoints, theme } from '@gassan-ui'
import { cn } from '@lib/cn'
import { formatPrice } from '@lib/formatPrice'
import { imgix } from '@lib/imigx'
import { motion } from 'framer-motion'
import { useTranslation } from 'next-i18next'
import { rem } from 'polished'
import { FC } from 'react'
import styled from 'styled-components'

const Description = styled(OldText)`
  display: -webkit-box;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
`

const UnknownSize = styled(Box)`
  width: 100%;
  background: ${theme.colors.pampas};
  position: relative;

  &:before {
    content: '';
    background: ${theme.colors.pampas};
    position: absolute;
    left: ${rem(22)};
    height: ${rem(11)};
    width: ${rem(11)};
    top: ${rem(-6)};
    transform: rotate(45deg);

    @media screen and (min-width: ${breakpoints.small}) {
      left: ${rem(39)};
      height: ${rem(14)};
      width: ${rem(14)};
      top: ${rem(-8)};
    }
  }
`

const Content = styled(motion(Grid))``

type Props = {
  sizeIsUnknown?: boolean
  variant?: 'pampas' | 'white'
  title: string
  src: string
  description: string
  count?: number
  price: number
  salePrice?: number
  discount?: number
  message?: string
  onRemoveItem?: () => void
}

const SummaryCartItem: FC<Props> = ({
  sizeIsUnknown,
  variant = 'white',
  title,
  description,
  src,
  count,
  price,
  salePrice,
  discount,
  message,
  onRemoveItem,
}) => {
  const { t } = useTranslation('other')

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{ duration: 0.2, ease: 'easeOut' }}
    >
      <Content
        gridTemplateColumns={{ _: '3.75rem 1fr', small: '5.5rem 1fr auto' }}
        gridColumnGap={{ _: '0.75rem', small: '1.25rem' }}
        mb="2rem"
        animate
      >
        <Box position="relative">
          <Image
            sources={[imgix(src, { width: 180 })]}
            alt={title}
            className={cn('aspect-square', variant === 'pampas' ? 'bg-white' : 'bg-shade-100')}
          />
          {count && (
            <Box
              display="flex"
              alignItems="center"
              justifyContent="center"
              border={`4px solid ${variant === 'pampas' ? theme.colors.pampasLight : 'white'}`}
              borderRadius="100%"
              bg="shades.800"
              color="pampasLight"
              fontFamily={theme.fonts.body}
              fontWeight="bold"
              fontSize={rem(11)}
              as="span"
              position="absolute"
              right={rem(-10)}
              top={rem(-10)}
              height={rem(28)}
              width={rem(28)}
              lineHeight="0"
            >
              {count}
            </Box>
          )}
        </Box>
        <Flex flexDirection="column" alignItems="flex-start">
          <OldText variant="regularBold" mb="0.25rem">
            {title}
          </OldText>
          <Description mb={{ small: '0.5rem' }} color="shades.500">
            {description}
          </Description>
          {message && (
            <Text variant="small" className="mb-2 text-shade-500">
              {`${t('additionalInfo')}: ${message}`}
            </Text>
          )}
          {onRemoveItem && (
            <Link as="button" onClick={onRemoveItem} variant="brown">
              {t('remove')}
            </Link>
          )}
          <Box flexDirection="column" textAlign="right" display={['flex', 'none']}>
            <OldText mb="0.25rem" style={{ textDecoration: salePrice && 'line-through' }}>
              {formatPrice(price, true)}
            </OldText>
            {salePrice && (
              <OldText variant="regularBold" mb={rem(6)}>
                {formatPrice(salePrice, true)}
              </OldText>
            )}
            {discount && <OldText mb="0.5rem">- {discount}%</OldText>}
          </Box>
        </Flex>
        <Box flexDirection="column" textAlign="right" display={['none', 'flex']}>
          <OldText mb="0.25rem" style={{ textDecoration: salePrice && 'line-through' }}>
            {formatPrice(price, true)}
          </OldText>
          {salePrice && (
            <OldText variant="regularBold" mb={rem(6)}>
              {formatPrice(salePrice, true)}
            </OldText>
          )}
          {discount && <OldText mb="0.5rem">- {discount}%</OldText>}
        </Box>
      </Content>
      {sizeIsUnknown && (
        <UnknownSize
          mb={['1.5rem', '2rem']}
          p={['0.5rem 0.75rem', '0.75rem 1rem']}
          mt={['-0.75rem', '-1rem']}
        >
          <OldText variant="small" color="tabaccoBrown" mb="0">
            {t('reachOutForRingsize')}
          </OldText>
        </UnknownSize>
      )}
    </motion.div>
  )
}

export default SummaryCartItem
