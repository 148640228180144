// @ts-strict-ignore
import { Item } from './helpers/types'

export function dataLayerBeginCheckout(items: Item[]) {
  if (!items || items.length === 0) {
    return
  }

  if (window['dataLayer']) {
    window['dataLayer'].push({ ecommerce: null })

    window['dataLayer'].push({
      event: 'begin_checkout',
      ecommerce: {
        items,
      },
    })
  }
}
