// @ts-strict-ignore
import { Item } from './types'
import { CartFragmentFragment } from '@generated'

export function cartItemsToDataLayerItems(products: CartFragmentFragment['items']): Item[] {
  if (!products || products.length === 0) {
    return []
  }
  let items: Item[] = []

  products.forEach((item) => {
    items.push({
      item_id: item.article.id,
      item_name: item.article.description,
      currency: 'EUR',
      index: 0,
      item_brand: item.article.brand,
      item_category: 'Apparel & Accessories',
      item_category2: 'Jewelry',
      item_category3: ['Horloge', 'Watch'].includes(item.article.articleType)
        ? 'Watches'
        : undefined,
      item_list_id: 'related_products',
      item_list_name: 'Related Products',
      price: Math.round((item.article.salePrice / 121 || item.article.price / 121) * 100) / 100,
      quantity: item.quantity,
    })
  })

  return items
}
